<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card padding>
          <v-container>
            <h1>Centro Distribuição</h1>
            <v-form
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="centro.nome"
                label="Nome"
                type="text"
                :rules="rules"
              />
              <v-row>
                <v-col cols="2">
                  <v-switch
                    v-model="centro.permiteRetirada"
                    label="Permite Retirada?"
                  />
                </v-col>
                <v-col cols="2">
                  <v-switch
                    v-model="centro.hotel"
                    label="Hotel?"
                  />
                </v-col>
              </v-row>
              <v-text-field
                v-model="centro.cep"
                label="CEP"
                type="text"
                :rules="rules"
              />
              <v-text-field
                v-model="centro.logradouro"
                label="Endereço"
                type="text"
                :rules="rules"
              />
              <v-text-field
                v-model="centro.numero"
                label="Número"
                type="number"
                :rules="rules"
              />
              <v-text-field
                v-model="centro.complemento"
                label="Complemento"
              />
              <v-text-field
                v-model="centro.bairro"
                label="Bairro"
                type="text"
                :rules="rules"
              />
              <v-autocomplete
                v-model="centro.cidade.estadoId"
                label="Estado"
                :items="estados"
                item-text="display"
                item-value="id"
                :rules="rules"
              />

              <v-autocomplete
                v-model="centro.cidadeId"
                label="Cidade"
                :items="cidades"
                item-text="display"
                item-value="id"
                :rules="rules"
              />
              <v-text-field
                v-model="centro.contato"
                label="Contato"
                type="text"
                :rules="rules"
              />
              <v-textarea
                v-model="centro.pontoDeReferencia"
                label="Ponto de Referência"
              />
              <v-textarea
                v-model="centro.googleMaps"
                label="Google Maps"
              />
              <v-btn
                color="primary"
                @click="submit"
              >
                Salvar
              </v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      estados: [],
      cidades: [],
      centro: {
        cidade: {},
      },
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    watch: {
      'centro.cidade.estadoId' (value) {
        if (value) {
          this.loadCidades(value)
            .then(({ data }) => { this.cidades = data })
        } else {
          this.cidades = []
        }
      },
    },
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
      this.$http.get('/endereco/estado')
        .then(resp => {
          this.estados = resp.data
        })
    },
    methods: {
      load () {
        this.$http.get(`/centros-distribuicao/${this.$route.params.id}`)
          .then(resp => {
            this.centro = resp.data
          })
      },
      loadCidades (estadoId) {
        return new Promise((resolve, reject) => {
          this.$http
            .get(`endereco/cidade?estadoId=${estadoId}`)
            .then(resolve).catch(reject)
        })
      },
      submit () {
        if (!this.$refs.form.validate()) {
          return
        }
        delete this.centro.cidade
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/centros-distribuicao/', this.centro)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/centro-distribuicao')
          })
      },
      put () {
        this.$http.put('/centros-distribuicao/', this.centro)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/centro-distribuicao')
          })
      },
    },
  }
</script>

<style>
.theme--light.v-label {
    color: rgba(0, 0, 0);
}
</style>
